/* eslint-disable react/jsx-no-target-blank */
// import {useIntl} from 'react-intl'
// import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function OMSAsideMenuMain() {
  // const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/oms'
        icon='shield-tick'
        title={'Order Management System'}
        fontIcon='bi-people-fill'
      />
      <div className='separator my-2'></div>
      {/* <AsideMenuItemWithSub to='/users' title='Users' hasBullet={false}> */}
      <AsideMenuItem state={''} to='/oms/orders' title='Orders' hasBullet={false} />
      <AsideMenuItem state={''} to='/oms/partners' title='Partners' hasBullet={false} />
      <AsideMenuItem state={''} to='/oms/shopOrders' title='Shop Orders' hasBullet={false} />
      <AsideMenuItem state={''} to='/oms/CallBack' title='Call Back Request' hasBullet={false} />

    </>
  )
}
