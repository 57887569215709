import {createAsyncThunk} from '@reduxjs/toolkit'
import {toast as message} from 'react-toastify'
import {getApi} from '../../../axios'
import {APPLICATION, EMPLOYERS, EMPLOYER_REPORT, JOBS} from '../../../axios/services'
import {API_ERROR} from '../../../global'

export const getJobs = createAsyncThunk('employee/getJobs', async (payload, thunkAPI) => {
  try {
    const response = await getApi(
      `${JOBS}?location=${payload?.location || ''}&company=${payload?.company || ''}&created_at=${
        payload?.created_at || ''
      }`
    )
    if (response?.data?.success) {
      return response?.data?.data
    }
  } catch (error) {
    message.error(error?.response?.data?.message || API_ERROR)
    return thunkAPI.rejectWithValue(error.response.data.message)
  }
})
export const getApplication = createAsyncThunk(
  'employee/getApplication',
  async (payload = '', thunkAPI) => {
    try {
      const response = await getApi(
        `${APPLICATION}?location=${payload?.location || ''}&created_at=${
          payload?.created_at || ''
        }&page=${payload?.page || 1}&job_id=${payload?.jobId || ''}&items_per_page=10`
      )
      if (response?.data?.success) {
        return response?.data?.data
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)

      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)

export const getEmployersReports = createAsyncThunk(
  'employee/getEmployersReports',
  async (payload = '', thunkAPI) => {
    try {
      const response = await getApi(
        `${EMPLOYER_REPORT}?location_on=${payload?.location || ''}&created_on=${
          payload?.created_at || ''
        }`
      )
      if (response?.data?.success) {
        return response?.data
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)

export const getEmployers = createAsyncThunk(
  'employee/getEmployers',
  async (payload = '', thunkAPI) => {
    try {
      const response = await getApi(
        `${EMPLOYERS}?page=${payload?.page || 1}&items_per_page=9&location_on=${
          payload?.location || ''
        }&created_on=${payload?.created || ''}`
      )
      if (response?.data?.success) {
        return response?.data?.data
      }
    } catch (error) {
      console.log('dvndkvlsnd', error)
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)
