import {createAsyncThunk} from '@reduxjs/toolkit'
import {toast as message} from 'react-toastify'
import {getApi} from '../../../axios'
import {CMS_REPORTS, VENDOR_PARTNERS} from '../../../axios/services'
import {API_ERROR} from '../../../global'
export const getCmsReports = createAsyncThunk(
  'cms/getCmsReports',
  async (payload = '', thunkAPI) => {
    try {
      const response = await getApi(
        `${CMS_REPORTS}?location=${payload?.location || ''}&got_created=${
          payload?.created_at || ''
        }`
      )
      if (response?.data?.success) {
        return response?.data?.data
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)

export const getVendorPartners = createAsyncThunk(
  'cms/getVendorPartners',
  async (payload, thunkAPI) => {
    try {
      const response = await getApi(
        `${VENDOR_PARTNERS}?got_created=${payload?.created || ''}&page=${
          payload?.page || 1
        }&items_per_page=10`
      )
      if (response?.data?.success) {
        return response?.data
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)
