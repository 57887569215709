import {getOrderById, getOrders, getPartners, getShopOrders,get_Callbackreuest} from '../actions/oms'

const {createSlice} = require('@reduxjs/toolkit')

const initialState = {
  partners: [],
  isLoading: false,
  orders: [],
  orderDetails: {},
  shopOrders: [],
  callrequest:[],
  totalShopPage: 0,
  totalOrdersPage: 0,
  totalPartnersPage: 0,
  totalcallrequestpage:0,
  cities: [],
  count: 0,
}

const oms = createSlice({
  name: 'oms',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPartners?.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getPartners?.fulfilled, (state, action) => {
      state.isLoading = false
      state.totalPartnersPage = action?.payload?.total_pages
      state.partners = action?.payload?.data || []
      if (state.count === 0) {
        state.cities = [...new Set(state.partners?.map((item) => item?.city))]
      }
      state.count += 1
    })
    builder.addCase(getPartners?.rejected, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(getOrders?.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getOrders?.fulfilled, (state, action) => {
      state.isLoading = false
      state.orders = action.payload.orders || [] 
      console.log( state.orders ,'66666666666666666666666')
      state.totalOrdersPage = action?.payload?.pagination?.total_pages
    })
    builder.addCase(getOrders?.rejected, (state, action) => {
      state.isLoading = false
    })

    builder.addCase(getOrderById?.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getOrderById?.fulfilled, (state, action) => {
      state.isLoading = false
      state.orderDetails = action?.payload
    })
    builder.addCase(getOrderById?.rejected, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(getShopOrders?.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getShopOrders?.fulfilled, (state, action) => {
      state.isLoading = false
      state.shopOrders = action?.payload?.data

      state.totalShopPage = action?.payload?.totalpages
    })
    builder.addCase(getShopOrders?.rejected, (state, action) => {
      state.isLoading = false
    })


    builder.addCase(get_Callbackreuest?.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(get_Callbackreuest?.fulfilled, (state, action) => {
      state.isLoading = false
      state.callrequest = action?.payload?.data?.data
      console.log(  state.callrequest ,'66666666666666666666666')

      state.totalcallrequestpage = action?.payload?.data?.pagination?.total_pages
      console.log(  state.totalcallrequestpage ,'--------------------')
      
    })
    builder.addCase(get_Callbackreuest?.rejected, (state, action) => {
      state.isLoading = false
    })
  },
})

export default oms.reducer
