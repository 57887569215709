/* eslint-disable react/jsx-no-target-blank */
// import {useIntl} from 'react-intl'
// import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function CMSAsideMenuMain() {
  // const intl = useIntl()

  return (
    <>
      <AsideMenuItem to='/cms' icon='element-11' title={'CMS'} fontIcon='bi-people-fill' />
      <div className='separator my-2'></div>
      {/* <AsideMenuItemWithSub to='/users' title='Users' hasBullet={false}> */}
      <AsideMenuItem state={''} to='/cms/overview' title='Overview' hasBullet={false} />

      <AsideMenuItem
        state={{user_role: 'nursefam'}}
        to='/cms/customers'
        title='Customer'
        hasBullet={false}
      />
    </>
  )
}
