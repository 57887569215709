// declare module '@yaireo/tagify';
// declare module '@yaireo/tagify/dist/react.tagify';
export const API_ERROR = 'Something went wrong!!'
export const GOOGLE_MAPS_API_KEY = 'AIzaSyA0sKhPX_7pw8IG4ZO_1P61agCVrP-BCHg'

export const isFile = (value) => {
  return value[0] instanceof File
}

export function checkFileType(value, dummy) {
  if (typeof value === 'string') {
    const newValue = value?.split('?')[0]
    // Check if the value is a URL
    if (newValue.match(/\.(jpeg|jpg|png|gif|pdf|svg)$/i)) {
      if (newValue.match(/\.(jpeg|jpg|png|gif|svg)$/i)) {
        return (
          <img
            style={{
              width: '80%',
              height: '80%',
            }}
            src={value}
            alt=''
          />
        )
      } else if (newValue.match(/\.pdf$/i)) {
        return (
          <iframe
          title="Document Viewer"
            style={{
              width: '80%',
            }}
            src={value}
            frameborder='0'
          ></iframe>
        )
      }
    } else {
      return (
        <img
          style={{
            width: '80%',
            height: '80%',
          }}
          src={dummy}
          alt=''
        />
      )
    }
  } else if (value && value[0] instanceof File) {
    // Check if the value is a File object
    const allowedExtensions = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'svg']
    const fileExtension = value[0].name.split('.').pop().toLowerCase()
    console.log(fileExtension, 'sdvknjno')
    if (allowedExtensions.includes(fileExtension)) {
      if (fileExtension.match(/(jpeg|jpg|png|gif|svg)$/i)) {
        return (
          <img
            style={{
              width: '80%',
              height: '80%',
            }}
            src={window.URL.createObjectURL(value[0])}
            alt=''
          />
        )
      } else if (fileExtension === 'pdf') {
        return (
          <iframe
          title="Document Viewer"
            style={{
              width: '80%',
            }}
            src={window.URL.createObjectURL(value[0])}
            frameborder='0'
          ></iframe>
        )
      }
    } else {
      return (
        <img
          style={{
            width: '80%',
            height: '80%',
          }}
          src={dummy}
          alt=''
        />
      )
    }
  } else {
    return (
      <img
        style={{
          width: '80%',
          height: '80%',
        }}
        src={dummy}
        alt=''
      />
    )
  }
}

export const sortText = (text) => {
  return text?.length > 35 ? `${text?.substring(0, 25)}...` : text
}

export function exportToCsv(data, headers, fileName = 'Data') {
  // Create an array to hold the CSV rows
  const csvRows = []
  // Add headers as the first row
  csvRows.push(headers.join(','))

  // Add user data to the CSV rows
  data.forEach((ele) => {
    // Join the values with commas and add to the CSV rows
    csvRows.push(ele.join(','))
  })

  // Create a Blob with the CSV string
  const csvString = csvRows.join('\n')
  const blob = new Blob([csvString], {type: 'text/csv'})

  // Create a download link and trigger the download
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = fileName + '.csv'
  link.click()
}

export function convertToTitleCase(inputString) {
  return inputString
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}
