import {useSelector} from 'react-redux'
import { toAbsoluteUrl} from '../../../helpers'
import { HeaderUserMenu} from '../../../partials'

const AsideFooter = () => {
  const {details} = useSelector((state) => state.users)

  return (
    <div
      className='aside-footer d-flex flex-column align-items-center flex-column-auto'
      id='kt_aside_footer'
    >
      {/* <div className='d-flex align-items-center mb-2'>
        <div
          className='btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light'
          data-kt-menu-trigger='click'
          data-kt-menu-overflow='true'
          data-kt-menu-placement='top-start'
          data-bs-toggle='tooltip'
          data-bs-placement='right'
          data-bs-dismiss='click'
          title='Quick links'
        >
          <KTIcon iconName='element-plus' className='fs-2 text-lg-1' />
        </div>

        <QuickLinks backgroundUrl='/media/misc/pattern-1.jpg' />
      </div> */}

      {/* <div className='d-flex align-items-center mb-3'>
        <div
          className='btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light'
          data-kt-menu-trigger='click'
          data-kt-menu-overflow='true'
          data-kt-menu-placement='top-start'
          data-bs-toggle='tooltip'
          data-bs-placement='right'
          data-bs-dismiss='click'
          title='Activity Logs'
          id='kt_activities_toggle'
        >
          <KTIcon iconName='chart-simple' className='fs-2 text-lg-1' />
        </div>
      </div> */}

      {/* <div className='d-flex align-items-center mb-2'>
        <div
          className='btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light'
          data-kt-menu-trigger='click'
          data-kt-menu-overflow='true'
          data-kt-menu-placement='top-start'
          data-bs-toggle='tooltip'
          data-bs-placement='right'
          data-bs-dismiss='click'
          title='Notifications'
        >
          <KTIcon iconName='element-11' className='fs-2 text-lg-1' />
        </div>

        <HeaderNotificationsMenu backgrounUrl='/media/misc/pattern-1.jpg' />
      </div> */}

      <div className='d-flex align-items-center mb-4' id='kt_header_user_menu_toggle'>
        <div
          className='cursor-pointer symbol symbol-40px'
          data-kt-menu-trigger='click'
          data-kt-menu-overflow='false'
          data-kt-menu-placement='top-start'
          title='User profile'
        >
          <img
            src={details?.profile_pic || toAbsoluteUrl('/media/avatars/300-1.jpg')}
            alt='avatar'
          />
        </div>

        <HeaderUserMenu />
      </div>
    </div>
  )
}

export {AsideFooter}
