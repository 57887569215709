import {getUserDetails, getUsersList} from '../actions/user'

const {createSlice} = require('@reduxjs/toolkit')

const initialState = {
  users: [],
  page: 0,
  items_per_page: 10,
  details: {},
  isLoading: false,
  current_page: 0,
  total_pages: 0,
}

const users = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsersList?.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(getUsersList?.fulfilled, (state, action) => {
      state.isLoading = false
      state.current_page = action?.payload?.current_page
      state.total_pages = action?.payload?.total_pages
      state.users = action?.payload?.users || []
      // state.customList = action?.payload?.custom_packages;
    })
    builder.addCase(getUsersList?.rejected, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(getUserDetails?.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getUserDetails?.fulfilled, (state, action) => {
      state.isLoading = false
      state.details = action.payload
    })
    builder.addCase(getUserDetails?.rejected, (state, action) => {
      state.isLoading = false
    })
  },
})

export default users.reducer
