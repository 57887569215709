import {createAsyncThunk} from '@reduxjs/toolkit'
import {toast as message} from 'react-toastify'
import {getApi, patchApi} from '../../../axios'
import {BGV_REQUESTS, CREATE_ORDER, HPMS_REPORTS, USER_STATE} from '../../../axios/services'
import {API_ERROR} from '../../../global'

export const getHpmsReports = createAsyncThunk(
  'hpms/getHpmsReports',
  async (payload = '', thunkAPI) => {
    try {
      const response = await getApi(`${HPMS_REPORTS}&created=${payload}`)
      if (response?.data?.success) {
        return response?.data?.data
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)

export const getBGVRequests = createAsyncThunk('hpms/getBGVRequests', async (payload, thunkAPI) => {
  try {
    const response = await getApi(
      `${BGV_REQUESTS}?verification_status=${payload?.status || ''}&created=${
        payload?.created || ''
      }&location_at=${payload?.city || ''}&page=${payload?.page || 1}&items_per_page=10`
    )
    if (response?.data?.success) {
      return response?.data
    }
  } catch (error) {
    message.error(error?.response?.data?.message || API_ERROR)
    return thunkAPI.rejectWithValue(error.response.data.message)
  }
})

export const getOrderById = createAsyncThunk(
  'hpms/getOrderById',
  async (payload = '', thunkAPI) => {
    try {
      const response = await getApi(CREATE_ORDER + '?order_id=' + payload)
      if (response?.data?.success) {
        return response?.data?.data
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)

export const patchOrder = createAsyncThunk('hpms/patchOrder', async ({body, next}, thunkAPI) => {
  try {
    const response = await patchApi(CREATE_ORDER, body)
    if (response?.data?.success) {
      next()
      message.success(response?.data?.message)
    }
  } catch (error) {
    message.error(error?.response?.data?.message || API_ERROR)
    return thunkAPI.rejectWithValue(error.response.data.message)
  }
})

export const patchUserState = createAsyncThunk(
  'users/patchUserState',
  async ({body, next}, thunkAPI) => {
    try {
      const response = await patchApi(`${USER_STATE}`, body)
      if (response.data?.success) {
        next()
        message.success(response.data?.message)
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)
