import {createAsyncThunk} from '@reduxjs/toolkit'
import {toast as message} from 'react-toastify'
import {deleteApi, getApi, patchApi} from '../../../axios'
import {ADDRESS, ADD_MEMBER, CMS_REPORTS, CUSTOMERS, USER_DETAILS} from '../../../axios/services'
import {API_ERROR} from '../../../global'
export const getCmsReports = createAsyncThunk(
  'cms/getCmsReports',
  async (payload = '', thunkAPI) => {
    try {
      const response = await getApi(
        `${CMS_REPORTS}?location=${payload?.location || ''}&got_created=${
          payload?.created_at || ''
        }`
      )
      if (response?.data?.success) {
        return response?.data?.data
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)

export const getCustomers = createAsyncThunk('cms/getCustomers', async (payload, thunkAPI) => {
  try {
    const response = await getApi(
      `${CUSTOMERS}?user_role=${payload?.user_role || 'all'}&got_created=${
        payload?.created || ''
      }&page=${payload?.page || 1}&items_per_page=10`
    )
    if (response?.data?.success) {
      return response?.data
    }
  } catch (error) {
    message.error(error?.response?.data?.message || API_ERROR)
    return thunkAPI.rejectWithValue(error.response.data.message)
  }
})

export const getAddresses = createAsyncThunk('cms/getAddresses', async (payload, thunkAPI) => {
  try {
    const response = await getApi(`${ADDRESS}?user=${payload}`)
    if (response?.data?.success) {
      return response?.data?.data
    }
  } catch (error) {
    message.error(error?.response?.data?.message || API_ERROR)
    return thunkAPI.rejectWithValue(error.response.data.message)
  }
})

export const deleteAddress = createAsyncThunk('cms/deleteAddress', async ({id, next}, thunkAPI) => {
  try {
    const response = await deleteApi(ADDRESS + '?address_id=' + id)
    if (response?.data?.success) {
      next()
      message.success(response?.data?.message)
    }
  } catch (error) {
    message.error(error?.response?.data?.message || API_ERROR)
    return thunkAPI.rejectWithValue(error.response.data.message)
  }
})
export const patchAddress = createAsyncThunk(
  'cms/patchAddress',
  async ({data, next = () => {}}, thunkAPI) => {
    try {
      const response = await patchApi(ADDRESS, data)
      if (response?.data?.success) {
        next()
        message.success(response?.data?.message)
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)

export const patchUserDetails = createAsyncThunk(
  'cms/patchUserDetails',
  async ({data, next = () => {}}, thunkAPI) => {
    try {
      const response = await patchApi(USER_DETAILS + 'findcare', data)
      if (response?.data?.success) {
        next()
        message.success(response?.data?.message)
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)

export const getMembers = createAsyncThunk('cms/getMembers', async (payload, thunkAPI) => {
  try {
    const response = await getApi(`${ADD_MEMBER}?user=${payload}`)
    if (response?.data?.success) {
      return response?.data?.data
    }
  } catch (error) {
    message.error(error?.response?.data?.message || API_ERROR)
    return thunkAPI.rejectWithValue(error.response.data.message)
  }
})

export const deleteMembers = createAsyncThunk('cms/deleteMembers', async ({id, next}, thunkAPI) => {
  try {
    const response = await deleteApi(ADD_MEMBER + '?member_id=' + id)
    if (response?.data?.success) {
      next()
      message.success(response?.data?.message)
    }
  } catch (error) {
    message.error(error?.response?.data?.message || API_ERROR)
    return thunkAPI.rejectWithValue(error.response.data.message)
  }
})
export const patchMember = createAsyncThunk(
  'cms/patchMember',
  async ({data, next = () => {}}, thunkAPI) => {
    console.log('patchMember', data, next)
    try {
      const response = await patchApi(ADD_MEMBER, data)
      if (response?.data?.success) {
        next()
        message.success(response?.data?.message)
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)
