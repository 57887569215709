import {getBGVRequests, getHpmsReports} from '../actions/hpms'
const {createSlice} = require('@reduxjs/toolkit')

const initialState = {
  isLoading: false,
  totalBGVTotalPage: 0,
  reports: {},
  bgvRequest: [],
}

const hpms = createSlice({
  name: 'hpms',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getHpmsReports?.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getHpmsReports?.fulfilled, (state, action) => {
      state.isLoading = false
      state.reports = action?.payload
    })
    builder.addCase(getHpmsReports?.rejected, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(getBGVRequests?.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getBGVRequests?.fulfilled, (state, action) => {
      state.isLoading = false
      state.bgvRequest = action?.payload?.data || []
      state.totalBGVTotalPage = action?.payload?.total_pages
    })
    builder.addCase(getBGVRequests?.rejected, (state, action) => {
      state.isLoading = false
    })
  },
})

export default hpms.reducer
