/* eslint-disable react/jsx-no-target-blank */
// import {useIntl} from 'react-intl'
// import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function VPMSAsideMenuMain() {
  // const intl = useIntl()

  return (
    <>
      <AsideMenuItem to='/vpms' icon='element-11' title={'VPMS'} fontIcon='bi-people-fill' />
      <div className='separator my-2'></div>
      {/* <AsideMenuItemWithSub to='/users' title='Users' hasBullet={false}> */}
      <AsideMenuItem state={''} to='/vpms/overview' title='Partners' hasBullet={false} />

      {/* <AsideMenuItem state={{oms_role: 'partner'}} to='oms/partners' title='Partner' hasBullet={false} /> */}
    </>
  )
}
