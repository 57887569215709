import {createAsyncThunk} from '@reduxjs/toolkit'
import {toast as message} from 'react-toastify'
import {getApi} from '../../../axios'
import {API_ERROR} from '../../../global'
import {TRANSACTIONLIST} from '../../../axios/services'

export const getTransactionlist = createAsyncThunk(
  'tsc/getTransactionlist',
  async (payload, thunkAPI) => {
    try {
      const response = await getApi(
        `${TRANSACTIONLIST}?got_created=${payload?.created || ''}&page=${
          payload?.page || 1
        }&items_per_page=10`
      )
      if (response?.data?.success) {
        return response?.data
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)
