import { getTransactionlist } from '../actions/tsc';

const {createSlice} = require('@reduxjs/toolkit')


const initialState = {
    reports: {},
    totalPages: 1,
    Transactionlist: [],
    isLoading: false,
  }

  const tsc = createSlice({
    name: 'tsc',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(getTransactionlist.pending, (state) => {
        state.isLoading = true;
      });
      builder.addCase(getTransactionlist.fulfilled, (state, action) => {
        state.isLoading = false;
        state.Transactionlist = action?.payload?.data?.transaction;
        state.totalPages=action?.payload?.data?.pagination?.total_pages
      });
      builder.addCase(getTransactionlist.rejected, (state) => {
        state.isLoading = false;
      });
    },
  });
  
  export default tsc.reducer;