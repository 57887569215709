import {getApplication, getEmployers, getEmployersReports, getJobs} from '../actions/employers'

const {createSlice} = require('@reduxjs/toolkit')

const initialState = {
  reports: {},
  jobs: [],
  employers: [],
  applications: [],
  totalJobsPages: 1,
  totalEmployersPage: 1,
  totalApplicationsPage: 1,
  isLoading: false,
}

const employers = createSlice({
  name: 'employers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getJobs?.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getJobs?.fulfilled, (state, action) => {
      state.isLoading = false
      state.jobs = action?.payload || []
      state.totalJobsPages = action?.payload?.total_pages
    })
    builder.addCase(getJobs?.rejected, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(getApplication?.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getApplication?.fulfilled, (state, action) => {
      state.isLoading = false
      state.applications = action?.payload || []
      state.totalApplicationsPage = action?.payload?.total_pages
    })
    builder.addCase(getApplication?.rejected, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(getEmployersReports?.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getEmployersReports?.fulfilled, (state, action) => {
      state.isLoading = false
      state.reports = action?.payload?.data
    })
    builder.addCase(getEmployersReports?.rejected, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(getEmployers?.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getEmployers?.fulfilled, (state, action) => {
      state.isLoading = false
      state.employers = action?.payload || []
      state.totalEmployersPage = action?.payload?.total_pages
    })
    builder.addCase(getEmployers?.rejected, (state, action) => {
      state.isLoading = false
    })
  },
})

export default employers.reducer
