import {getHppmsReports, getPartnerDetails} from '../actions/hppms'

const {createSlice} = require('@reduxjs/toolkit')

const initialState = {
  reports: {},
  partnerDetails: {},
  isLoading: false,
}

const hppms = createSlice({
  name: 'hppms',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getHppmsReports?.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getHppmsReports?.fulfilled, (state, action) => {
      state.isLoading = false
      state.reports = action?.payload
    })
    builder.addCase(getHppmsReports?.rejected, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(getPartnerDetails?.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getPartnerDetails?.fulfilled, (state, action) => {
      state.isLoading = false
      state.partnerDetails = action?.payload
    })
    builder.addCase(getPartnerDetails?.rejected, (state, action) => {
      state.isLoading = false
    })
  },
})
export default hppms.reducer
