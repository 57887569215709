/* eslint-disable react/jsx-no-target-blank */
// import {useIntl} from 'react-intl'
// import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function HPPMSAsideMenuMain() {
  // const intl = useIntl()

  return (
    <>
      <AsideMenuItem to='/hppms' icon='element-11' title={'HPPMS'} fontIcon='bi-people-fill' />
      <div className='separator my-2'></div>
      {/* <AsideMenuItemWithSub to='/users' title='Users' hasBullet={false}> */}
      <AsideMenuItem state={''} to='/hppms/overview' title='Overview' hasBullet={false} />

      <AsideMenuItem
        state={{oms_role: 'partner'}}
        to='oms/partners'
        title='Partner'
        hasBullet={false}
      />
    </>
  )
}
