import {combineReducers} from 'redux'
import catalogs from './catalogs'
import users from './user'
import oms from './oms'
import hpms from './hpms'
import employer from './employer'
import hppms from './hppms'
import cms from './cms'
import vpms from './vpms'
import tsc from './tsc'


const reducers = combineReducers({
  catalogs,
  users,
  oms,
  hpms,
  employer,
  hppms,
  cms,
  vpms,
  tsc,
})

export default reducers
