/* eslint-disable react/jsx-no-target-blank */
// import {useIntl} from 'react-intl'
// import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function EmployersAsideMenuMain() {
  // const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/employers'
        icon='element-11'
        title={'Employers'}
        fontIcon='bi-people-fill'
      />
      <div className='separator my-2'></div>
      <AsideMenuItem state={''} to='/employers/overview' title='Overview' hasBullet={false} />
      <AsideMenuItem state={''} to='/employers/jobs' title='Jobs' hasBullet={false} />
      <AsideMenuItem to='/employers/applications' title='Applications' hasBullet={false} />
    </>
  )
}
