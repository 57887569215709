/* eslint-disable react/jsx-no-target-blank */
// import {useIntl} from 'react-intl'
// import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function UserAsideMenuMain() {
  // const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/users'
        icon='shield-tick'
        title={'User Management'}
        fontIcon='bi-people-fill'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Users</span>
        </div>
      </div>

      {/* <AsideMenuItemWithSub to='/users' title='Users' hasBullet={false}> */}
      <AsideMenuItem state={''} to='/users/all' title='All' hasBullet={true} />
      <AsideMenuItem
        state={{user_role: 'employer'}}
        to='/users/employers'
        title='Employers'
        hasBullet={true}
      />
      <AsideMenuItem
        state={{user_role: 'nurse'}}
        to='/users/nurses'
        title='Nurses'
        hasBullet={true}
      />
      <AsideMenuItem
        state={{user_role: 'nursefam'}}
        to='/users/customers'
        title='NurseFam Customers'
        hasBullet={true}
      />
      <AsideMenuItem
        state={{user_role: 'partner'}}
        to='/users/partners'
        title='Partners'
        hasBullet={true}
      />
      <AsideMenuItem
        state={{user_role: 'healon'}}
        to='/users/healoncustomer'
        title='HealOn Customer'
        hasBullet={true}
      />
      {/* </AsideMenuItemWithSub> */}

      {/* <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Products' hasBullet={false}>
        <AsideMenuItem to='/crafted/pages/wizards/horizontal' title='Horizontal' hasBullet={true} />
        <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
      </AsideMenuItemWithSub> */}
      {/* </AsideMenuItemWithSub> */}
      {/* <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Request'
        // icon='profile-circle'
        // fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Publish' hasBullet={false} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub> */}
      {/* <AsideMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub> */}
      {/* <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-11'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub> */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div> */}
      {/* <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub> */}
      {/* <AsideMenuItem
        to='/apps/user-management/users'
        icon='shield-tick'
        title='User management'
        fontIcon='bi-layers'
      /> */}
    </>
  )
}
