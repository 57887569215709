import React from 'react'
import {AsideMenuItem} from './AsideMenuItem'

export function TSCAsideMenuMain() {
  return (
    <>
      <AsideMenuItem to='/transaction' icon='element-11' title={'Transaction'} fontIcon='bi-people-fill' />
       <div className='separator my-2'></div>
       <AsideMenuItem state={''} to='/transaction/transaction-list' title='Transaction-list' hasBullet={false} />
    </>
  )
}

