import {getAddresses, getCmsReports, getCustomers, getMembers} from '../actions/cms'

const {createSlice} = require('@reduxjs/toolkit')

const initialState = {
  reports: {},
  totalPages: 1,
  customers: [],
  isLoading: false,
  addresses: [],
  members: [],
}

const cms = createSlice({
  name: 'cms',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCmsReports?.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getCmsReports?.fulfilled, (state, action) => {
      state.isLoading = false
      state.reports = action?.payload
    })
    builder.addCase(getCmsReports?.rejected, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(getCustomers?.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getCustomers?.fulfilled, (state, action) => {
      console.log(action?.payload?.total_pages, 'vdsnkjo')
      state.isLoading = false
      state.customers = action?.payload?.data || []
      state.totalPages = action?.payload?.total_pages || 1
    })
    builder.addCase(getCustomers?.rejected, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(getMembers?.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getMembers?.fulfilled, (state, action) => {
      state.isLoading = false
      state.members = action.payload
    })
    builder.addCase(getMembers?.rejected, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(getAddresses?.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getAddresses?.fulfilled, (state, action) => {
      state.isLoading = false
      state.addresses = action.payload
    })
    builder.addCase(getAddresses?.rejected, (state, action) => {
      state.isLoading = false
    })
  },
})
export default cms.reducer
