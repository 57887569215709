import axios from 'axios'
import {BASEURL} from './services'

axios.defaults.baseURL = BASEURL

/**
 *
 * @param {*} path  endpoint
 * @returns data from api
 */
export const getApi = (path: string) => {
  const authToken = JSON.parse(localStorage.getItem('auth') || '')?.access
  const config = {
    headers: {
      Authorization: authToken ? `Bearer ${authToken}` : null,
    },
  }
  return axios.get(path, config)
}

/**
 *
 * @param {*} path   endpoint
 * @param {*} data object of data
 * @returns   data from api
 */
export const postApi = (path: string, data: object) => {
  const authToken = JSON.parse(localStorage.getItem('auth') || '')?.access
  const config = {
    headers: {
      Authorization: authToken ? `Bearer ${authToken}` : null,
    },
  }
  return axios.post(path, data, config)
}

/**
 *
 * @param {*} path   endpoint
 * @param {*} data object of data
 * @returns   data from api
 */
export function putApi(path: string, data: object) {
  const authToken = JSON.parse(localStorage.getItem('auth') || '')?.access
  const config = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  }

  return axios.put(path, data, config)
}

/**
 *
 * @param {*} path   endpoint
 * @param {*} data object of data
 * @returns   data from api
 */
export function patchApi(path: string, data: object) {
  const authToken = JSON.parse(localStorage.getItem('auth') || '')?.access
  const config = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  }

  return axios.patch(path, data, config)
}

/**
 *
 * @param {*} path   endpoint
 * @param {*} data params
 * @returns   data from api
 */
export function deleteApi(path: string, data: object) {
  const authToken = JSON.parse(localStorage.getItem('auth') || '')?.access
  const config = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  }

  return axios.delete(path, {...config})
}
