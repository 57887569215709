import {createAsyncThunk} from '@reduxjs/toolkit'
import {toast as message} from 'react-toastify'
import {HPPMS_REPORTS, PARTNER_DETAILS} from '../../../axios/services'
import {getApi, patchApi} from '../../../axios'
import {API_ERROR} from '../../../global'

export const getHppmsReports = createAsyncThunk(
  'hppms/getHppmsReports',
  async (payload = '', thunkAPI) => {
    try {
      const response = await getApi(
        `${HPPMS_REPORTS}?city=${payload?.city || ''}&created_on=${payload?.created_at || ''}`
      )
      if (response?.data?.success) {
        return response?.data?.data
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)

export const getPartnerDetails = createAsyncThunk(
  'hppms/getPartnerDetails',
  async (payload, thunkAPI) => {
    try {
      const response = await getApi(`${PARTNER_DETAILS}?user=${payload}`)
      if (response?.data?.success) {
        return response?.data?.data && response?.data?.data?.nurse
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)

export const patchPartnerDetails = createAsyncThunk(
  'hppms/patchPartnerDetails',
  async ({data, next = () => {}}, thunkAPI) => {
    try {
      const response = await patchApi(PARTNER_DETAILS, data)
      if (response?.data?.success) {
        next()
        // return response?.data?.data;
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)
